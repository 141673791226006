import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TVShowWatchlist } from './tvshow.watchlist.model';
import { HttpResponse } from './httpresponse.model';

@Injectable()

export class TVShowWatchlistService {
  private apiUrl: string;
  private currentTvshowwatchlistname: string;
  constructor(private http: HttpClient) {
    // Backend URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  setCurrentTVShowWatchlistName(iname: string) {
    this.currentTvshowwatchlistname = iname;
  }

  getCurrentTVShowWatchlistName() {
    return this.currentTvshowwatchlistname;
  }

  getUserTVShowWatchlists(ibabenutzerid: number, iapikey: string): Observable<Array<TVShowWatchlist>> {
    return this.http.get<Array<TVShowWatchlist>>(this.apiUrl + 'getTVShowWatchlists?ba_benutzer_id='
      + ibabenutzerid + '&api_key=' + iapikey);
  }

  // Erstellung einer neuen TVShow Watchlist
  addNewTVShowWatchlist(ibabenutzerid: number, iapikey: string, watchlistTitle: string, friends: Array<number>): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    let friendlist: string;
    if (friends === null) {
      friendlist = '';
    } else {
      friendlist = friends.toString();
    }

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('watchlistTitle', watchlistTitle);
    body.append('friends', friendlist);


    const result = this.http.post<HttpResponse>(this.apiUrl + 'createTVShowWatchlist', body, options);

    return result;
  }

  // Hole die TVWatchlist
  getWatchlistDetails(ibabenutzerid: number, iapikey: string, itvwatchlistid: string): Observable<TVShowWatchlist> {
    return this.http.get<TVShowWatchlist>(this.apiUrl + 'getTvShowWatchlistDetail?ba_benutzer_id='
      + ibabenutzerid + '&api_key=' + iapikey + '&tv_watchlist_id=' + itvwatchlistid);
  }

  // Ändere WatchlistNamen
  // tslint:disable-next-line:max-line-length
  tvChangeWatchlistName(ibabenutzerid: number, iapikey: string, itvwatchlistid: string, itvwatchlistname: string): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'tvChangeWatchlistName?ba_benutzer_id='
      + ibabenutzerid + '&api_key=' + iapikey + '&tv_watchlist_id=' + itvwatchlistid
      + '&watchlistTitle=' + itvwatchlistname);
  }

  // Benutzerliste der Watchlist ändern
  // tslint:disable-next-line:max-line-length
  tvChangeWatchlistBenutzer(ibabenutzerid: number, iapikey: string, itvwatchlistid: string, ibafriendid: number, ibenutzerchecked: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'tvChangeWatchlistBenutzer?ba_benutzer_id='
      + ibabenutzerid + '&api_key=' + iapikey + '&tv_watchlist_id=' + itvwatchlistid
      + '&ba_friend_id=' + ibafriendid + '&benutzer_checked=' + ibenutzerchecked);
  }

  // Entfernt den Benutzer aus der Watchlist
  public removeUserFromWatchlist(ibabenutzerid: number, iapikey: string, itvwatchlistid: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'removeUserFromTVWatchlist?ba_benutzer_id='
      + ibabenutzerid + '&api_key=' + iapikey + '&tv_watchlist_id=' + itvwatchlistid);
  }
}
