import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MovieSearchResult } from './movie.search.result.model';
import { HttpResponse } from './httpresponse.model';
import { IUser } from './user.model';

@Injectable()
export class MovieSearchService {
  private apiUrl: string;
  constructor(private http: HttpClient) {

    // lokale Datein
    // this.apiUrl = 'assets/movie_watchlist.json';

    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  // Suche nach Filmen
  searchNewMovie(imovietitle: string): Observable<Array<MovieSearchResult>> {
    return this.http.get<Array<MovieSearchResult>>(this.apiUrl + 'searchNewMovie?movie_title=' + imovietitle);
  }

  // Füge Filme der Watchlist hinzu
  addMoviesToWatchlist(iuserModel: IUser, iwatchlistid: string, imoviesToAdd: Array<MovieSearchResult>): Observable<HttpResponse> {
    // API-URL erweitern

    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    let themoviedbids: Array<string> = [];
    for (let i = 0; i <= imoviesToAdd.length - 1; i++) {
      themoviedbids.push(imoviesToAdd[i].THEMOVIEDB_ID);
    }

    const body = new FormData();
    body.append('ba_benutzer_id', iuserModel.BA_BENUTZER_ID.toString());
    body.append('api_key', iuserModel.API_KEY);
    body.append('mo_watchlist_id', iwatchlistid);
    body.append('themoviedb_ids', JSON.stringify(themoviedbids));

    const result = this.http.post<HttpResponse>(this.apiUrl + 'addMoviesToWatchlist', body, options);

    return result;
  }

}
