import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { IUser } from './user.model';

@Injectable()

export class UserSerivce {

  private userSubject = new Subject<IUser>();

  constructor(private localtorage: Storage) {
  }

  sendUserObj(iuserObj: IUser) {
    this.userSubject.next(iuserObj);
  }

  getUserObj(): Observable<IUser> {
    return this.userSubject.asObservable();
  }

  private async loadFromLocalStorage(settingName) {
    return await this.localtorage.get(`setting:${settingName}`);
  }
}

