import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpResponse } from './httpresponse.model';
import { IUser } from './user.model';
import { Menu } from './menu.model';

@Injectable()

export class ProfilService {
  private apiUrl: string;
  public userModel: IUser;

  constructor(private http: HttpClient) {
    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  public changeUserData() {

  }

  // Das Benutzer-Kennwort ändern
  public changePassword(ibabenutzerid: number, iapikey: string, ioldPassword: string, inewPassword: string): Observable<HttpResponse> {

    // HTTP-Header erstellen
    const httpHeaders = new HttpHeaders();
    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('oldPassword', ioldPassword);
    body.append('newPassword', inewPassword);

    const result = this.http.post<HttpResponse>(this.apiUrl + 'changeProfilPassword', body, options);

    return result;
  }

  // tslint:disable-next-line:max-line-length
  public changeProfilData(ibabenutzerid: number, iapikey: string, ibenutzername: string, ivorname: string, inachname: string, iemail: string, igeburtsdatum: string): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('benutzername', ibenutzername);
    body.append('vorname', ivorname);
    body.append('nachname', inachname);
    body.append('email', iemail);
    body.append('geburtsdatum', igeburtsdatum);

    const result = this.http.post<HttpResponse>(this.apiUrl + 'changeProfilData', body, options);

    return result;
  }

  public changeProfilPicture(ibabenutzerid: number, iapikey: string, ipicture: any): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();
    const formData = new FormData();

    // httpHeaders.append('Content-Type', 'application/json');
    // httpHeaders.append('Accept', 'application/json');
    // httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    // httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('file', ipicture);

    const result = this.http.post<HttpResponse>(this.apiUrl + 'changeProfilPicture', body, options);

    return result;
  }

  public changeProfilColor(ibabenutzerid: number, iapikey: string, inewColor: string): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();
    const formData = new FormData();

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('newProfilColor', inewColor);

    const result = this.http.post<HttpResponse>(this.apiUrl + 'changeProfilColor', body, options);

    return result;
  }
}
