import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Movie } from './movie.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from './httpresponse.model';

@Injectable()
export class MovielistService {

  public apiUrl: string;
  private selectedMovie: Movie;
  public filter: BehaviorSubject<string>;

  constructor(private http: HttpClient) {
    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
    this.filter = new BehaviorSubject('');
  }

  getMovies(imowatchlistid: string): Observable<Array<Movie>> {
    return this.http.get<Array<Movie>>('./assets/movie_list_' + imowatchlistid + '.json');
  }

  getMoviesFromWatchlist(ibabenutzerid: number, iapikey: string, imowatchlistid): Observable<Array<Movie>> {
    return this.http.get<Array<Movie>>(this.apiUrl + 'getMoviesFromWatchlist?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_watchlist_id=' + imowatchlistid);

  }

  removeMovieFromWatchlist(ibabenutzerid: number, iapikey: string, imowatchlistid: string, imovieid: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'removeMovieFromWatchlist?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_watchlist_id=' + imowatchlistid + '&mo_movie_id=' + imovieid);
  }

  // setze den Film als gesehen
  setMovieWatched(ibabenutzerid: number, iapikey: string, imovieid): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'setMovieWatched?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_movie_id=' + imovieid);
  }

  // setze den Film zu den Favoriten
  setMovieFavorite(ibabenutzerid: number, iapikey: string, imovieid): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'setMovieFavorite?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_movie_id=' + imovieid);
  }

  // Hole einen Film mit der ID
  getMovieFromID(ibabenutzerid: number, iapikey: string, imovieid): Observable<Movie> {
    return this.http.get<Movie>(this.apiUrl + 'getMovieByID?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_movie_id=' + imovieid);
  }

  public setMovie(imovie: Movie) {
    this.selectedMovie = imovie;
  }
  public getMovie() {
    return this.selectedMovie;
  }

  public addFilter(istring: string) {
    this.filter.next(istring);
  }

  public getFilter() {
    return this.filter.asObservable();
  }
}
