import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { IUser } from './user.model';
import { TVShowEpisode } from './tvshow.episode.model';
import { HttpResponse } from './httpresponse.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()

export class TVShowDetailService {
  private currentTVShowID: number;
  private currentSeasonId: number;
  public currentSeason: Array<TVShowEpisode>;
  private currentEpisode: TVShowEpisode;
  public apiUrl: string;

  constructor(private http: HttpClient) {
    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  // tslint:disable-next-line:max-line-length
  public getTVShowSeasonEpisodes(ibabenutzerid: number, iapikey: string, itvshowid: string, itvshowseasonid: string): Observable<Array<TVShowEpisode>> {

    return this.http.get<Array<TVShowEpisode>>(this.apiUrl + 'getTVShowSeasonEpisodes?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_tvshow_id=' + itvshowid +
      '&tv_tvshow_season_id=' + itvshowseasonid);
  }

  // setzt den gesehen status einer Episode
  // tslint:disable-next-line:max-line-length
  public setTVShowEpisodeWatched(ibabenutzerid: number, iapikey: string, itvshowid: string, itvshowseasonid: string, itv_tvshowepisodesid: number, iwatched: boolean): Observable<HttpResponse> {
    // Watched Variable von boolean in Number umwandeln
    let watchedNumber: number;
    if (iwatched) {
      // gesehen
      watchedNumber = 1;
    } else {
      watchedNumber = 0;
    }

    return this.http.get<HttpResponse>(this.apiUrl + 'setTVShowEpisodeWatched?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_tvshow_id=' + itvshowid +
      '&tv_tvshow_seasons_id=' + itvshowseasonid + '&tv_tvshow_episodes_id=' + itv_tvshowepisodesid + '&watched=' + watchedNumber);
  }

  // Setzt die gesamte Staffel auf gesehen
  // tslint:disable-next-line:max-line-length
  public setTVShowSeasonWatched(ibabenutzerid: number, iapikey: string, itvshowid: string, itvshowseasonid: string, iwatched: boolean): Observable<HttpResponse> {
    // Watched Variable von boolean in Number umwandeln
    let watchedNumber: number;
    if (iwatched) {
      // gesehen
      watchedNumber = 1;
    } else {
      watchedNumber = 0;
    }

    return this.http.get<HttpResponse>(this.apiUrl + 'setTVShowSeasonWatched?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_tvshow_id=' + itvshowid +
      '&tv_tvshow_seasons_id=' + itvshowseasonid + '&watched=' + watchedNumber);
  }

  // Bewertung einer Episode setzen
  // tslint:disable-next-line:max-line-length
  public setTVShowEpisodeBewertung(ibabenutzerid: number, iapikey: string, itvshowid: string, itvshowseasonid: string, itvshowepisodesid: number, ibewertung: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'setTVShowEpisodeBewertung?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_tvshow_id=' + itvshowid +
      '&tv_tvshow_seasons_id=' + itvshowseasonid + '&tv_tvshow_episode_id=' + itvshowepisodesid + '&bewertung=' + ibewertung);
  }

  public getCurrentEpisode() {
    return this.currentEpisode;
  }

  public setCurrentEpisode(episode: TVShowEpisode) {
    this.currentEpisode = episode;
  }

  public getCurrentTVShowId() {
    return this.currentTVShowID;
  }

  public setCurrentTVShowId(itvshowid: number) {
    this.currentTVShowID = itvshowid;
  }

  public getCurrentSeasonId() {
    return this.currentSeasonId;
  }

  public setCurrentSeasonId(seasonid: number) {
    this.currentSeasonId = seasonid;
  }
}
