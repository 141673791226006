import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { IUser } from './user.model';
import { Menu } from './menu.model';
import { HttpResponse } from './httpresponse.model';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class AuthService {

  // https://forum.ionicframework.com/t/ionic-native-http-instead-of-angulars-http/101326/5
  // https://stackoverflow.com/questions/44163506/wrap-cordova-http-plugin-promise-as-observable

  private apiUrl: string;
  private userMenu: BehaviorSubject<Array<Menu>>;
  constructor(
    private http: HttpClient,
    private natvieHttp: HTTP) {

    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
    this.userMenu = new BehaviorSubject(null);
  }

  // public login(iusername: string, ipassword: string): Observable<Array<IUser>> {
  //   const httpHeaders = new HttpHeaders();

  //   httpHeaders.append('Content-Type', 'application/json');
  //   httpHeaders.append('Accept', 'application/json');
  //   httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
  //   httpHeaders.append('Access-Control-Allow-Origin', '*');

  //   const options = {
  //     headers: httpHeaders
  //   };

  //   const body = new FormData();
  //   body.append('username', iusername);
  //   body.append('password', ipassword);

  //   return Observable.create((observable) => {
  //     this.natvieHttp.post(this.apiUrl + 'doLogin', body, options).then((result) => {
  //       observable.next(result.data);
  //       observable.complete();
  //     });
  //   });
  // }


  public login(iusername: string, ipassword: string): Observable<Array<IUser>> {

    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('username', iusername);
    body.append('password', ipassword);

    const result = this.http.post<Array<IUser>>(this.apiUrl + 'doLogin', body, options);
    return result;
  }

  // Ein Neuer Benutzer will sich registrieren
  public regist(iusername, ipassword, iemail): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('username', iusername);
    body.append('password', ipassword);
    body.append('email', iemail);

    const result = this.http.post<HttpResponse>(this.apiUrl + 'doRegist', body, options);
    return result;
  }

  // Das Menü des Benutzers holen
  public getUserMenu(ibabenutzerid: number, iapikey: string): Observable<Array<Menu>> {
    return this.http.get<Array<Menu>>(this.apiUrl + 'getBenutzerModule' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey);
  }

  public setUserModuleMenu(menu: Array<Menu>) {
    this.userMenu.next(menu);
  }

  public getUserModuleMenu() {
    return this.userMenu.asObservable();
  }

  public sendRecoveryCode(iusername: string): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'doSendResetCode' + '?username=' + iusername);
  }

  public sendNewPassword(iresetcode: string, inewpassword): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('resetcode', iresetcode);
    body.append('newpassword', inewpassword);

    return this.http.post<HttpResponse>(this.apiUrl + 'setNewResetPassword', body, options);
  }
}
