import { Component, OnInit, Input } from '@angular/core';
import { Storage } from '@ionic/storage';
import { FriendsService } from '../shared/friends.service';
import { MovieWatchlistService } from '../shared/movie.watchlist.servce';
import { Friend } from '../shared/friend.model';
import { ModalController, LoadingController, ToastController, NavParams } from '@ionic/angular';
import { IUser } from '../shared/user.model';

@Component({
  selector: 'app-new-watchlist',
  templateUrl: './new-watchlist.component.html',
  styleUrls: ['./new-watchlist.component.scss']
})
export class NewWatchlistComponent implements OnInit {
  public modalModus: number;
  private userModel: IUser;
  public selectedFriends: Array<number>;
  private isLoading = false;
  public mowatchlistid: number;
  public mowatchlistname: string;
  public watchlistTitel: string;
  private watchlistOrgTitel: string;
  public friendlistChecklist: boolean;
  public btnWatchlistSave: string;
  public newUserinWatchlist: Array<Friend>;

  constructor(
    public storage: Storage,
    public friendService: FriendsService,
    public watchlistService: MovieWatchlistService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public toastController: ToastController,
    private navParams: NavParams
  ) {
    // Initialisiere Variablen
    this.modalModus = 0;
    this.mowatchlistname = 'Neue Watchlist';
    this.btnWatchlistAdd = false;
    this.selectedFriends = null;
    this.friendlistChecklist = false;
    this.btnWatchlistSave = 'Watchlist erstellen';

    // Wenn eine MO_WATCHLIST_ID mit gegeben wurde in variable speichern
    if (this.navParams.get('mowatchlistid') > 0) {
      this.mowatchlistid = this.navParams.get('mowatchlistid');
      this.modalModus = 1;
      this.mowatchlistname = 'Watchlist editieren';
      this.btnWatchlistSave = 'Watchlist speichern';
    }

  }
  public friendlist: Array<Friend>;
  public btnWatchlistAdd: boolean;
  public newWatchlistName: string;

  ngOnInit() {
    // Hole userModel aus dem Speicher
    this.loadFromLocalStorage('USER').then(user => {
      this.userModel = user;
      this.friendService.getFriendList(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY).subscribe(result => {
        this.friendlist = result;
        this.friendlist.forEach(el => {
          el.CHECKED = false;
        });
      });

      // Wenn EditModus
      if (this.modalModus === 1) {
        // Edit Mode
        this.btnWatchlistAdd = true;
        // Hole die Watchlist Details
        this.watchlistService.getWatchlistDetails(this.userModel.BA_BENUTZER_ID,
          this.userModel.API_KEY, this.mowatchlistid).subscribe(watchlist => {
            // Forms füllen
            this.watchlistTitel = watchlist[0].WATCHLIST_NAME;
            // Original WatchlistTitel zwischenspeichern
            this.watchlistOrgTitel = watchlist[0].WATCHLIST_NAME;

            // Liste mit Freunden matchen
            watchlist[0].WATCHLIST_FRIENDS.split(',').forEach(ele => {

              // letztes Element weg werfen
              if (+ele.split('~')[0] > 0) {

                const friendIndex = this.friendlist.findIndex(search => search.BA_FREUND_ID === +ele.split('~')[0]);

                // Wenn er nicht in der Liste gefunden wurde
                if (friendIndex < 0) {
                  // Freund hinzufügen
                  this.friendlist.push({
                    BA_FREUND_ID: +ele.split('~')[0],
                    FREUND_BENUTZERNAME: ele.split('~')[1],
                    FREUND_PROFIL_IMG: null,
                    FREUND_RANG: null,
                    ANZAHL_NEUE_NACHRICHTEN: null,
                    BESTAETIGT: 1,
                    CHECKED: true
                  });
                } else {
                  // Er ist bereits in der Liste der Freunde und Mitglied der Watchlist
                  this.friendlist[friendIndex].CHECKED = true;
                }
              }
            });
            this.friendlistChecklist = true;
          });
      }
    });
  }

  // Modal schließen
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  // Watchlist Titel INPUT
  inputWatchlistTitle(ivalue: any) {

    if (ivalue.length > 4) {
      this.watchlistTitel = ivalue;
      this.btnWatchlistAdd = true;

      // Nur wenn sich der Name geändert hat oder ein Freund hinzugefügt/entfernt wurde
      // Button freigeben
      if (ivalue !== this.watchlistTitel) {
        this.btnWatchlistAdd = true;
      }
    }
  }

  // Freunde wurden ausgewählt und OK gedrückt
  selectChanged(ievent: any) {
    this.selectedFriends = ievent.detail.value;
  }

  // Speichere die Watchlist
  saveWatchlist() {
    // Neue Watchlist
    if (this.modalModus === 0) {
      if (this.watchlistTitel !== '') {

        // Show LoadingLayer
        this.presentLoadingLayer();

        // Daten an API senden
        this.watchlistService.saveWatchlist(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY, this.watchlistTitel,
          this.selectedFriends).subscribe(result => {

            // Wenn das Speichern erfolgreich war
            if (result.RESPONSE_MESSAGE === 'OK') {

              // LoadingLayer, Modal ausblenden, Toast anzeigen
              this.dismissLoadingLayer();
              this.dismiss();
              this.presentToast('Speichern erfolgreich');
            } else {
              // LoadingLayer ausblenden
              this.dismissLoadingLayer();
              this.presentToast('Speichern nicht erfolgreich');
            }
          });
      }
    } else if (this.modalModus === 1) {
      // Watchlist editieren -> Änderungen speichern
      this.presentLoadingLayer();

      // Prüfen ob sich der Watchlistname geändert hat
      if (this.watchlistTitel !== this.watchlistOrgTitel) {
        // Watchlist Name hat sich geändert
        // Watchlistnamen an API senden
        this.watchlistService.moChangeWatchlistName(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY,
          this.mowatchlistid, this.watchlistTitel).subscribe(result => {
            if (result.RESPONSE_MESSAGE === 'OK') {
              this.presentToast('Watchlist wurde aktualisiert');
            }
          });
      }

      // Watchlist Benutzer anpassen
      this.friendlist.forEach(friendItem => {
        // Jeden Benutzer einzelnt an API senden
        let checkedasNumber = 0;
        if (friendItem.CHECKED) {
          checkedasNumber = 1;
        } else {
          checkedasNumber = 0;
        }

        this.watchlistService.moChangeWatchlistBenutzer(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY,
          this.mowatchlistid, friendItem.BA_FREUND_ID, checkedasNumber).subscribe(userResult => {
          });
      });

      // LoadingLayer ausblenden
      this.dismissLoadingLayer();
      this.dismiss();
    }

  }

  async presentLoadingLayer() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then();
        }
      });
    });
  }

  // Im EditModus wurde etwas an der Benutzerliste geänder
  watchlistUserChanged(ibafreundid: number, ievent: any) {
    if (this.friendlistChecklist) {
      // DEBUG: später entferne
      this.presentToast(ibafreundid.toString() + ' : ' + ievent.target.checked);

      // Benutzerliste aktualiiseren
      this.friendlist.find(selectedFriend => selectedFriend.BA_FREUND_ID === ibafreundid).CHECKED = ievent.target.checked;
    }
  }

  async dismissLoadingLayer() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then();
  }

  public async loadFromLocalStorage(settingName) {
    return await this.storage.get(`setting:${settingName}`);
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1000
    });
    toast.present();
  }

}
