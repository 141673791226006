import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Movie } from '../shared/movie.model';
import { MovieDetailService } from '../shared/movie.detail.service';

@Component({
  selector: 'app-image-view-modal',
  templateUrl: './image-view-modal.component.html',
  styleUrls: ['./image-view-modal.component.scss'],
})
export class ImageViewModalComponent implements OnInit {
  public coverUrl: string;
  public movie: Movie;

  constructor(
    private modalController: ModalController,
    private movieDetailService: MovieDetailService
  ) {

    // Aktuelles Cover aus dem Service laden
    this.coverUrl = this.movieDetailService.getCover();
  }

  ngOnInit() { }

  // Modal schließen
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
