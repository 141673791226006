import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './shared/auth.service';
import { UserSerivce } from './shared/user.service';
import { MovielistService } from './shared/movielist.service';
import { MovieWatchlistService } from './shared/movie.watchlist.servce';
import { FriendsService } from './shared/friends.service';
import { HomeService } from './shared/home.service';
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { NavController } from '@ionic/angular';

import { TVShowWatchlistService } from './shared/tvshow.watchlist.service';
import { TvshowWatchlistModalComponent } from './tvshow-watchlist-modal/tvshow-watchlist-modal.component';

import { MovieSearchPagePageModule } from './movie-search-page/movie-search-page.module';
import { MovieSearchPagePage } from './movie-search-page/movie-search-page.page';
import { MovieSearchService } from './shared/movie.search.service';
import { CinemaMoviesService } from './shared/cinema-movies.service';

import { PopoverMovieFilterComponent } from './popover-movie-filter/popover-movie-filter.component';
import { NewWatchlistComponent } from './new-watchlist/new-watchlist.component';
import { ProfilService } from './shared/profil.service';
import { ModalCinemaToWatchlistComponent } from './modal-cinema-to-watchlist/modal-cinema-to-watchlist.component';
import { ImageViewModalComponent } from './image-view-modal/image-view-modal.component';
import { MovieDetailService } from './shared/movie.detail.service';
import { CommentModalComponent } from './comment-modal/comment-modal.component';

import { TVShowlistService } from './shared/tvshowlist.service';
import { TvshowSearchModalComponent } from './tvshow-search-modal/tvshow-search-modal.component';

import { TVShowDetailService } from './shared/tvshow-detail.service';

import { NewsModalComponent } from './news-modal/news-modal.component';
import { EpisodenDetailModalComponent } from './episoden-detail-modal/episoden-detail-modal.component';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';

// import { TvshowEpisodeListitemComponent } from './tvshow-episode-listitem/tvshow-episode-listitem.component';

// INFO: Für das Modal muss das Module und die Page importiert werden
// Module -> imports
// Page -> entryComponent
// NICHT: declaration und entryComponent

@NgModule({
  declarations: [
    AppComponent,
    PopoverMovieFilterComponent,
    NewWatchlistComponent,
    ModalCinemaToWatchlistComponent,
    ImageViewModalComponent,
    CommentModalComponent,
    TvshowWatchlistModalComponent,
    TvshowSearchModalComponent,
    NewsModalComponent,
    EpisodenDetailModalComponent],
  entryComponents: [
    MovieSearchPagePage,
    PopoverMovieFilterComponent,
    NewWatchlistComponent,
    ModalCinemaToWatchlistComponent,
    ImageViewModalComponent,
    CommentModalComponent,
    TvshowWatchlistModalComponent,
    TvshowSearchModalComponent,
    NewsModalComponent,
    EpisodenDetailModalComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    MovieSearchPagePageModule,
    CommonModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NavController,
    AuthService,
    UserSerivce,
    MovieWatchlistService,
    MovielistService,
    HomeService,
    FriendsService,
    MovieSearchService,
    ProfilService,
    CinemaMoviesService,
    MovieDetailService,
    TVShowWatchlistService,
    TVShowlistService,
    HTTP,
    TVShowDetailService,
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
