import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavParams, ModalController, ToastController, LoadingController } from '@ionic/angular';
import { MovieWatchlistService } from '../shared/movie.watchlist.servce';
import { IUser } from '../shared/user.model';
import { IMovieWatchlist } from '../shared/movie.watchlist.model';
import { MovieSearchService } from '../shared/movie.search.service';
import { MovieSearchResult } from '../shared/movie.search.result.model';

@Component({
  selector: 'app-modal-cinema-to-watchlist',
  templateUrl: './modal-cinema-to-watchlist.component.html',
  styleUrls: ['./modal-cinema-to-watchlist.component.scss'],
})
export class ModalCinemaToWatchlistComponent implements OnInit {

  private userModel: IUser;
  public isLoading: boolean;
  public moviewatchlist: Array<IMovieWatchlist>;
  public themoviedbid: string;
  public movietitel: string;
  private selectedWatchlistId: number;
  public btnSaveMovieState: boolean;
  private moviesToAddArray: Array<MovieSearchResult>;
  constructor(
    private localStorage: Storage,
    private movieWatchlistService: MovieWatchlistService,
    private movieSearchService: MovieSearchService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    private navParams: NavParams
  ) {

    // Button deaktivieren
    this.btnSaveMovieState = false;
    // prüfen ob eine Movie-ID mitgegeben wurde
    if (this.navParams.get('themoviedbid') > 0) {
      this.themoviedbid = this.navParams.get('themoviedbid');
    } else {
      // Modal schließen
      this.dismiss();
    }

    if (this.navParams.get('movietitel') > 0) {
      this.movietitel = this.navParams.get('movietitel');
    }

    // Lade User aus dem Speicher
    this.loadFromLocalStorage('USER').then(benutzer => {
      this.userModel = benutzer;
      // Benutzer Watchlist holen
      this.movieWatchlistService.getUserWatchlists(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY).subscribe(watchlists => {
        this.moviewatchlist = watchlists;
      });
    });

    // Dummy-Array initialisieren
    this.moviesToAddArray = [];
    this.isLoading = false;
  }

  ngOnInit() { }

  // Eine Watchlist wurde aus der Liste ausgewählt
  public watchlistSelect(iwatchlistid: number) {
    // Ausgewählte Watchlist zwischenspeichern
    this.selectedWatchlistId = iwatchlistid;

    // Wenn eine Watchlist ausgewählt wurde Button freigeben
    this.btnSaveMovieState = true;
  }

  // Button Speichern
  public saveMovietoWatchlist() {
    // prüfen ob eine Watchlist ausgewählt wurde
    if (this.selectedWatchlistId) {
      // Film der hinzugefügt werden soll
      const movieToAdd: MovieSearchResult = {
        THEMOVIEDB_ID: this.themoviedbid,
        TITLE: null,
        COVER_URL: null,
        CHECKED: true
      };

      // Film in Dummy Array hinzufügen
      this.moviesToAddArray.push(movieToAdd);

      // Film zur Watchlist hinzufügen
      this.presentLoadingLayer();
      this.movieSearchService.addMoviesToWatchlist(this.userModel, this.selectedWatchlistId.toString(),
        this.moviesToAddArray).subscribe(res => {
          if (res.RESPONSE_MESSAGE === 'OK') {
            this.presentToast('Film wurde hinzugefügt');
            this.dismiss();
          } else {
            this.presentToast('Film bereits in der Watchlist');
          }
        });
      // LoadingLayer wieder ausblenden
      this.dismissLoadingLayer();
    }
  }

  // Modal schließen
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  // Toast anzeigen
  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  // --------------------------LoadingLayer--------------------------
  async presentLoadingLayer() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Filme werden geladen',
      cssClass: 'loadingLayer-cinema'
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then();
        }
      });
    });
  }

  async dismissLoadingLayer() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then();
  }

  // Hole Daten aus dem lokalen Speicher
  public async loadFromLocalStorage(settingName) {
    return await this.localStorage.get(`setting:${settingName}`);
  }

}
