import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '*',
    redirectTo: 'home', // INFO: Hier den Pfad angeben zu dem am Start navigiert werden soll
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'movie-watchlist',
    loadChildren: () => import('./movie-watchlist/movie-watchlist.module').then(m => m.MovieWatchlistPageModule)
  },
  {
    path: 'tvshow-watchlist',
    loadChildren: () => import('./tvshow-watchlist/tvshow-watchlist.module').then(m => m.TvshowWatchlistPageModule)
  },
  {
    path: 'tvshow-list/:id',
    loadChildren: () => import('./tvshow-list/tvshow-list.module').then(m => m.TvshowListPageModule)
  },
  {
    path: 'movie-list/:id',
    loadChildren: () => import('./movie-list/movie-list.module').then(m => m.MovieListPageModule)
  },
  {
    path: 'friends',
    loadChildren: () => import('./friends/friends.module').then(m => m.FriendsPageModule)
  },
  {
    path: 'movie-detail/:id',
    loadChildren: () => import('./movie-detail/movie-detail.module').then(m => m.MovieDetailPageModule)
  },
  {
    path: 'tvshow-detail/:id',
    loadChildren: () => import('./tvshow-detail/tvshow-detail.module').then(m => m.TvshowDetailPageModule)
  },
  {
    path: 'friend-messages/:friendid',
    loadChildren: () => import('./friend-messages/friend-messages.module').then(m => m.FriendMessagesPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./profil/profil.module').then(m => m.ProfilPageModule)
  },
  {
    path: 'cinema-movies',
    loadChildren: () => import('./cinema-movies/cinema-movies.module').then(m => m.CinemaMoviesPageModule)
  },
  {
    path: 'regist',
    loadChildren: () => import('./regist/regist.module').then(m => m.RegistPageModule)
  },  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'new-password-form',
    loadChildren: () => import('./new-password-form/new-password-form.module').then( m => m.NewPasswordFormPageModule)
  }

];

@NgModule({
  imports: [
    // useHash -> Url wird mit einem Hash versehen um auch beim Reload der Seite
    // das routing zu ermöglichen
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
