import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Movie } from './movie.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from './httpresponse.model';
// http://81.169.138.242/php-rest-api/api.php/watchlist/getCinemaMovies

@Injectable()
export class CinemaMoviesService {
  public apiUrl: string;
  constructor(private http: HttpClient) {
    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  // Hole die aktuellen Kino-Filme
  getCinemaMovies(page: number): Observable<Array<Movie>> {
    return this.http.get<Array<Movie>>(this.apiUrl + 'getCinemaMovies?page=' + page);
  }
}
