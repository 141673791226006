import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TimeLine } from './timeline.model';
import { News } from './news.model';
import { LastSeen } from './lastseen.model';
import { FriendsLastSeen } from './friendslastseen.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HomeService {
  public apiUrl: string;
  private newsArray: Array<News>;
  private profilColor: BehaviorSubject<string>;

  constructor(private http: HttpClient) {
    // http://127.0.0.1/api-test/login.php
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
    this.profilColor = new BehaviorSubject('');
  }

  public getNewsArray() {
    return this.newsArray;
  }

  public setNewsArray(inews: Array<News>) {
    this.newsArray = inews;
  }

  // Holt die Freundesliste
  getFriendList(ibabenutzerid: number, iapikey: string): Observable<Array<TimeLine>> {
    return this.http.get<Array<TimeLine>>(this.apiUrl + 'getUserTimeline' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey);
  }

  // Holt die aktuellen News
  getNews(ibabenutzerid: number, iapikey: string): Observable<Array<News>> {
    return this.http.get<Array<News>>(this.apiUrl + 'getNews' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey);
  }

  // Holt die zuletzt gesehenen Elemente
  getLasSeen(ibabenutzerid: number, iapikey: string): Observable<Array<LastSeen>> {
    return this.http.get<Array<LastSeen>>(this.apiUrl + 'getUserLastSeen' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey);
  }

  // Holt die zuletzt gesehenen Elemente der Freunde
  getFriendsLastSeen(ibabenutzerid: number, iapikey: string): Observable<Array<FriendsLastSeen>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<Array<FriendsLastSeen>>(this.apiUrl + 'getFriendsLastSeen' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey);
  }

  public getProfilColor() {
    return this.profilColor.asObservable();
  }

  public setProfilColor(color: string) {
    this.profilColor.next(color);
  }
}
