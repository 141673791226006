import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MovieSearchResult } from '../shared/movie.search.result.model';
import { MovieSearchService } from '../shared/movie.search.service';
import { Storage } from '@ionic/storage';
import { IUser } from '../shared/user.model';
import { ToastController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-movie-search-page',
  templateUrl: './movie-search-page.page.html',
  styleUrls: ['./movie-search-page.page.scss'],
})
export class MovieSearchPagePage implements OnInit {
  @ViewChild('inputbox', { static: false }) inputBox;
  public userModel: IUser;
  public mowatchlistid: string;
  public moviesearch: Array<MovieSearchResult>;
  public moviesToAdd: Array<MovieSearchResult>;
  public btnMovieAdd: boolean;
  private isLoading: boolean;
  public searchTitel: string;

  constructor(
    private localStorage: Storage,
    private navParams: NavParams,
    public toastCtrl: ToastController,
    private changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public movieSearchService: MovieSearchService
  ) {
    this.btnMovieAdd = false;
    this.moviesearch = [];
    this.moviesToAdd = [];
    this.userModel = null;

    // Übergabe Parameter auslesen
    this.mowatchlistid = this.navParams.get('imowatchlistid');
    this.userModel = this.navParams.get('iuser');
  }

  ngOnInit() {
    // Focus setzen in der Suchleiste
    setTimeout(() => {
      this.inputBox.setFocus();
    }, 150);
  }

  searchMovieInput(search: any) {
    this.searchTitel = search.target.value;

    // Wenn die Suchbox leer ist Ergebnisse löschen
    if (this.searchTitel.length === 0) {
      this.moviesearch = [];
    }

    // Trigger refresh
    this.changeDetectorRef.detectChanges();
  }

  // Film wurde angetippt -> Film checked
  movieChecked(ithemoviedbid: string, ititel: string, icover: string) {

    // Prüfen ob der Film gecheckt wurde oder nicht
    const tempMovie: MovieSearchResult = {
      THEMOVIEDB_ID: ithemoviedbid,
      TITLE: ititel,
      COVER_URL: icover,
      CHECKED: true
    };

    // Füge Film zur Add List
    this.moviesToAdd.push(tempMovie);

    // Item entfernen
    this.moviesearch = this.moviesearch.filter(mo => mo.THEMOVIEDB_ID !== ithemoviedbid);

    // Prüfen ob mindestens ein Film in der liste ist
    if (this.moviesToAdd.length > 0) {
      // Button Hinzufügen anzeigen
      this.btnMovieAdd = true;
    } else {
      // Button Hinzufügen ausblenden
      this.btnMovieAdd = false;
    }
  }

  // Film aus Liste entfernen und wieder in den SearchResult schreiben
  badgeRemoveItem(ithemoviedbid: string) {
    // prüfen ob eine themoviedbid mit gegeben wurde
    if (ithemoviedbid !== 'undefined') {

      // Film zwischen speichern
      const delMovAdd: MovieSearchResult = this.moviesToAdd.find(delMo => delMo.THEMOVIEDB_ID === ithemoviedbid);
      delMovAdd.CHECKED = false;

      // schmeiße Film aus der Liste
      this.moviesToAdd = this.moviesToAdd.filter(obj => obj.THEMOVIEDB_ID !== ithemoviedbid);

      // Film in die SearchResult Liste pushen
      this.moviesearch.push(delMovAdd);

      // Wenn keine Filme mehr zum hinzufügen vorhanden sind
      if (this.moviesToAdd.length < 1) {
        this.btnMovieAdd = false;
      }
    }
  }

  // Füge Filme zur Watchlist hinzu
  public addMoviesToWatchlist() {

    this.presentLoadingLayer();
    this.movieSearchService.addMoviesToWatchlist(this.userModel, this.mowatchlistid, this.moviesToAdd).subscribe(httpResult => {

      // Wenn Anfrage geklappt hat
      if (httpResult.RESPONSE_MESSAGE === 'OK') {
        this.dismiss();
      } else {
        this.presentToast('Errorcode: ' + httpResult.ERROR_CODE + ' | ' + httpResult.ERROR_MESSAGE);
      }
      this.dismissLoadingLayer();
    });
  }

  // Modal schließn
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  // Hole Daten aus dem Speicher
  public async loadFromLocalStorage(settingName) {
    return await this.localStorage.get(`setting:${settingName}`);
  }

  // Speichere Daten im Speicher
  public saveToLocalStorage(settingName, value) {
    return this.localStorage.set(`setting:${settingName}`, value);
  }

  async presentLoadingLayer() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then();
        }
      });
    });
  }

  async dismissLoadingLayer() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then();
  }

  // Suchbox leeren
  public clearInput(ievent: any) {
    if (ievent.target.value === '') {
      // Suche leeren
      this.moviesearch = null;
    }
  }

  // Suchbutton NEU:
  public startSearch() {
    if (this.searchTitel.length > 1) {
      // Suche nach dem Film über API
      this.movieSearchService.searchNewMovie(this.searchTitel).subscribe(searchResult => {
        this.moviesearch = searchResult;
      });
    } else {
      this.presentToast('Bitte einen Titel eingeben');
    }

  }
}
