import { Component, OnInit } from '@angular/core';
import { FilterMenu } from '../shared/filter.model';
import { MovielistService } from '../shared/movielist.service';

@Component({
  selector: 'app-popover-movie-filter',
  templateUrl: './popover-movie-filter.component.html',
  styleUrls: ['./popover-movie-filter.component.scss'],
})
export class PopoverMovieFilterComponent implements OnInit {

  public filterMenu: Array<FilterMenu>;

  constructor(
    private movieListService: MovielistService
  ) {
    this.filterMenu = [
      {
        filterMenuId: 1,
        nofilter: false,
        textTrue: 'Alle anzeigen',
        textFalse: 'Alle Anzeigen',
        iconTrue: null,
        iconFalse: null,
        type: 'button',
        isChecked: true
      },
      {
        filterMenuId: 2,
        nofilter: true,
        textTrue: 'A-Z',
        textFalse: 'Z-A',
        iconTrue: 'arrow-dropdown-circle',
        iconFalse: 'arrow-dropup-circle',
        type: 'icon',
        isChecked: true
      },
      {
        filterMenuId: 3,
        nofilter: false,
        textTrue: 'gesehen',
        textFalse: 'ungesehen',
        iconTrue: 'eye',
        iconFalse: 'eye-off',
        type: 'icon',
        isChecked: true
      },
      {
        filterMenuId: 4,
        nofilter: false,
        textTrue: 'Favorite',
        textFalse: 'kein Favorite',
        iconTrue: 'bookmark',
        iconFalse: 'bookmark',
        type: 'icon',
        isChecked: true
      }, {
        filterMenuId: 5,
        nofilter: false,
        textTrue: 'Erscheinung desc',
        textFalse: 'Erscheinung asc',
        iconTrue: 'arrow-dropup-circle',
        iconFalse: 'arrow-dropdown-circle',
        type: 'icon',
        isChecked: false
      },
      // {
      //   filterMenuId: 4,
      //   nofilter: false,
      //   textTrue: 'Bewertung',
      //   textFalse: 'Bewertung',
      //   iconTrue: 'star',
      //   iconFalse: 'star-outline',
      //   type: 'icon',
      //   isChecked: false
      // },
      // {
      //   filterMenuId: 5,
      //   nofilter: true,
      //   textTrue: 'Neu',
      //   textFalse: 'Neu',
      //   iconTrue: 'alert',
      //   iconFalse: 'alert',
      //   type: 'icon',
      //   isChecked: false
      // }
    ];
  }

  ngOnInit() { }

  close() {
  }

  // Ein Filter wurde angeklickt
  filterClick(ifilterid: number) {

    // isChecked setzen
    this.filterMenu.forEach(item => {
      if (item.filterMenuId === ifilterid) {
        // Es ist das gesuchte Element
        if (item.isChecked) {
          item.isChecked = false;
          this.movieListService.addFilter(item.textFalse);
        } else {
          item.isChecked = true;
          this.movieListService.addFilter(item.textTrue);
        }

        // Wenn false
        if (!item.nofilter) {
          // Alle anzeigen setzen
          if (this.filterMenu[0].isChecked) {
            this.filterMenu[0].isChecked = false;
            if (item.filterMenuId === 1) {
              this.movieListService.addFilter(item.textTrue);
            }
          }
        }
      }
    });
  }
}
