import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, ToastController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Friend } from '../shared/friend.model';
import { FriendsService } from '../shared/friends.service';
import { TVShowWatchlistService } from '../shared/tvshow.watchlist.service';
import { IUser } from '../shared/user.model';

@Component({
  selector: 'app-tvshow-watchlist-modal',
  templateUrl: './tvshow-watchlist-modal.component.html',
  styleUrls: ['./tvshow-watchlist-modal.component.scss'],
})
export class TvshowWatchlistModalComponent implements OnInit {
  public tvshowwatchlistid: string;
  public tvshowWatchlistName: string;
  public tvshowWatchlistNameOrg: string;
  public modalModus: number;
  private isLoading: boolean;
  public tvwatchlistname: string;
  public btnWatchlistSaveTitel: string;
  public btnWatchlistAdd: boolean;
  public friendlist: Array<Friend>;
  public userModel: IUser;
  public friendlistChecklist: boolean;
  public selectedFriends: Array<number>;

  constructor(
    public storage: Storage,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public friendListService: FriendsService,
    private tvshowWatchlistService: TVShowWatchlistService,
    private navParams: NavParams
  ) {
    // Initialisierung
    this.isLoading = false;
    this.tvwatchlistname = 'Neue TV Show Watchlist';
    this.btnWatchlistSaveTitel = 'Speichern';
    this.btnWatchlistAdd = false;
    this.modalModus = 0;
    this.friendlistChecklist = false;
    this.selectedFriends = null;

    // User aus dem Speicher holen
    this.loadFromLocalStorage('USER').then(user => {
      this.userModel = user;

      // Friendlist holen
      this.friendListService.getFriendList(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY).subscribe(friends => {
        this.friendlist = friends;
        this.friendlistChecklist = true;
      });

      // Wenn eine TV_WATCHLIST_ID mit gegeben wurde in variable speichern
      if (this.navParams.get('tvshowwatchlistid') > 0) {
        this.tvshowwatchlistid = this.navParams.get('tvshowwatchlistid');
        this.modalModus = 1;
        this.tvwatchlistname = 'Watchlist editieren';

        // Watchlist Infos holen
        this.btnWatchlistAdd = true;
        // Hole die Watchlist Details
        this.tvshowWatchlistService.getWatchlistDetails(this.userModel.BA_BENUTZER_ID,
          this.userModel.API_KEY, this.tvshowwatchlistid).subscribe(watchlist => {

            // Watchlistnamen übernehmen
            this.tvshowWatchlistName = watchlist[0].WATCHLIST_NAME;
            this.tvshowWatchlistNameOrg = watchlist[0].WATCHLIST_NAME;

            // Liste mit Freunden matchen
            watchlist[0].WATCHLIST_FRIENDS.split(',').forEach(ele => {

              // letztes Element weg werfen
              if (+ele.split('~')[0] > 0) {

                const friendIndex = this.friendlist.findIndex(search => search.BA_FREUND_ID === +ele.split('~')[0]);

                // Wenn er nicht in der Liste gefunden wurde
                if (friendIndex < 0) {
                  // Freund hinzufügen
                  this.friendlist.push({
                    BA_FREUND_ID: +ele.split('~')[0],
                    FREUND_BENUTZERNAME: ele.split('~')[1],
                    FREUND_PROFIL_IMG: null,
                    FREUND_RANG: null,
                    ANZAHL_NEUE_NACHRICHTEN: null,
                    BESTAETIGT: 1,
                    CHECKED: true
                  });
                } else {
                  // Er ist bereits in der Liste der Freunde und Mitglied der Watchlist
                  this.friendlist[friendIndex].CHECKED = true;
                }
              }
            });
            this.friendlistChecklist = true;
          });
      } else {
        // Neue Watchlist
        this.modalModus = 0;
      }

      if (this.navParams.get('tvshowwatchlisttitel')) {
        this.tvshowWatchlistName = this.navParams.get('tvshowwatchlisttitel');
      }

    });
  }

  ngOnInit() { }

  // Watchlist speichern
  public saveTVWatchlist() {

    if (this.modalModus === 0) {
      // Neue Watchlist
      console.log('Neue TV Watchlist');
      if (this.tvshowWatchlistName !== '') {
        // Es wurde ein Name vergeben
        // Watchlist speichern
        this.tvshowWatchlistService.addNewTVShowWatchlist(this.userModel.BA_BENUTZER_ID,
          this.userModel.API_KEY, this.tvshowWatchlistName, this.selectedFriends).subscribe(result => {
            // Wenn das Speichern erfolgreich war
            if (result.RESPONSE_MESSAGE === 'OK') {

              // LoadingLayer, Modal ausblenden, Toast anzeigen
              this.dismissLoadingLayer();
              this.dismiss();
              this.presentToast('Speichern erfolgreich');
            } else {
              // LoadingLayer ausblenden
              this.dismissLoadingLayer();
              this.presentToast('Speichern nicht erfolgreich');
            }
          });
      }
    }
    // bestehnde Watchlist editiert
    if (this.modalModus === 1) {

      this.presentLoadingLayer();

      // Prüfen ob sich der Watchlistname geändert hat
      if (this.tvshowWatchlistName !== this.tvshowWatchlistNameOrg) {
        // Watchlist Name hat sich geändert
        // Watchlistnamen an API senden
        this.tvshowWatchlistService.tvChangeWatchlistName(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY,
          this.tvshowwatchlistid, this.tvshowWatchlistName).subscribe(result => {
            if (result.RESPONSE_MESSAGE === 'OK') {
              this.presentToast('Watchlist wurde aktualisiert');
            }
          });
      }

      // Watchlist Benutzer anpassen
      this.friendlist.forEach(friendItem => {
        // Jeden Benutzer einzelnt an API senden
        let checkedasNumber = 0;
        if (friendItem.CHECKED) {
          checkedasNumber = 1;
        } else {
          checkedasNumber = 0;
        }

        // Die Benutzer aktualisieren
        this.tvshowWatchlistService.tvChangeWatchlistBenutzer(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY,
          this.tvshowwatchlistid, friendItem.BA_FREUND_ID, checkedasNumber).subscribe(userResult => {
          });
      });

      // LoadingLayer ausblenden und Schließen
      this.dismissLoadingLayer();
      this.dismiss();
    }
  }

  // Freunde wurden ausgewählt und OK gedrückt
  selectChanged(ievent: any) {
    this.selectedFriends = ievent.detail.value;
  }

  // Watchlist Titel INPUT
  inputWatchlistTitle(ivalue: any) {

    if (ivalue.length > 4) {
      this.tvshowWatchlistName = ivalue;
      this.btnWatchlistAdd = true;

      // Nur wenn sich der Name geändert hat oder ein Freund hinzugefügt/entfernt wurde
      // Button freigeben
      if (ivalue !== this.tvshowWatchlistName) {
        this.btnWatchlistAdd = true;
      }
    }
  }

  // Im EditModus wurde etwas an der Benutzerliste geänder
  watchlistUserChanged(ibafreundid: number, ievent: any) {
    if (this.friendlistChecklist) {
      // DEBUG: später entferne
      this.presentToast(ibafreundid.toString() + ' : ' + ievent.target.checked);

      // Benutzerliste aktualiiseren
      this.friendlist.find(selectedFriend => selectedFriend.BA_FREUND_ID === ibafreundid).CHECKED = ievent.target.checked;
    }
  }


  // ---------------------------- LoadingLayer --------------------------------------
  async presentLoadingLayer() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismissLoadingLayer() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  public async loadFromLocalStorage(settingName) {
    return await this.storage.get(`setting:${settingName}`);
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1000
    });
    toast.present();
  }

  // Modal schließen
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
