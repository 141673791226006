import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HomeService } from '../shared/home.service';
import { News } from '../shared/news.model';

@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss'],
})
export class NewsModalComponent implements OnInit {
  public newsArray: Array<News>;

  constructor(
    private modalController: ModalController,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    // News aus dem Service holen
    this.newsArray = this.homeService.getNewsArray();
  }

  // Modal schließen
  public dismiss() {
    this.modalController.dismiss();
  }

}
