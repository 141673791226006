import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { TVShow } from './tvshow.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from './httpresponse.model';
import { MovieSearchResult } from './movie.search.result.model';
import { IUser } from './user.model';
import { TVShowSeason } from './tvshow.season.model';

@Injectable()
export class TVShowlistService {
  public apiUrl: string;

  constructor(private http: HttpClient) {
    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  getTVShowsFromWatchlist(ibabenutzerid: number, iapikey: string, itvwatchlistid): Observable<Array<TVShow>> {
    return this.http.get<Array<TVShow>>(this.apiUrl + 'getTVShowsFromWatchlist?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_watchlist_id=' + itvwatchlistid);

  }

  // Suche nach Filmen
  searchNewTVShow(imovietitle: string): Observable<Array<MovieSearchResult>> {
    return this.http.get<Array<MovieSearchResult>>(this.apiUrl + 'searchNewTVShow?tvshow_title=' + imovietitle);
  }

  // Füge Serie der Watchlist hinzu
  addMoviesToWatchlist(iuserModel: IUser, iwatchlistid: string, ithemoviedbid: string): Observable<HttpResponse> {
    // API-URL erweitern

    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', iuserModel.BA_BENUTZER_ID.toString());
    body.append('api_key', iuserModel.API_KEY);
    body.append('tv_watchlist_id', iwatchlistid);
    body.append('themoviedb_id', ithemoviedbid);

    const result = this.http.post<HttpResponse>(this.apiUrl + 'addTVShowToWatchlist', body, options);

    return result;
  }

  // TVShow Details holen
  getTVShowSeasonDetails(ibabenutzerid: number, iapikey: string, itvshowid: string): Observable<Array<TVShowSeason>> {
    return this.http.get<Array<TVShowSeason>>(this.apiUrl + 'getTVShowSeasonInfos?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_tvshow_id=' + itvshowid);
  }

  // Serie aus einer Watchlist entfernen
  // tslint:disable-next-line:max-line-length
  public removeTVShowFromWatchlist(ibabenutzerid: number, iapikey: string, itvwatchlistid: string, itvshowid: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'removeTVShowFromWatchlist?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&tv_watchlist_id=' + itvwatchlistid + '&tv_tvshow_id=' + itvshowid);
  }
}
