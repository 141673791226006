import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { TVShowDetailService } from '../shared/tvshow-detail.service';
import { TVShowEpisode } from '../shared/tvshow.episode.model';
import { Storage } from '@ionic/storage';
import { IUser } from '../shared/user.model';

@Component({
  selector: 'app-episoden-detail-modal',
  templateUrl: './episoden-detail-modal.component.html',
  styleUrls: ['./episoden-detail-modal.component.scss'],
})
export class EpisodenDetailModalComponent implements OnInit {
  public episode: TVShowEpisode;
  public tvshowid: number;
  public tvshowSeasonId: number;
  public userModel: IUser;

  constructor(
    public storage: Storage,
    private modalController: ModalController,
    private tvshowDetailService: TVShowDetailService
  ) { }

  ngOnInit() {
    this.loadFromLocalStorage('USER').then(user => {
      this.userModel = user;
    });
    this.episode = this.tvshowDetailService.getCurrentEpisode();
    this.tvshowid = this.tvshowDetailService.getCurrentTVShowId();
    this.tvshowSeasonId = this.tvshowDetailService.getCurrentSeasonId();
    // Testausgabe
    console.log('Modal Episode', this.episode);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  // Benutzer hat eine Bewertung abgegeben
  public changeUserBewertung(bewertung: number) {
    this.episode.BENUTZER_BEWERTUNG = bewertung;
    // Neue Bewertung an Datenbank senden
    this.tvshowDetailService.setTVShowEpisodeBewertung(this.userModel.BA_BENUTZER_ID,
      this.userModel.API_KEY, this.tvshowid.toString(), this.tvshowSeasonId.toString(),
      this.episode.TV_TVSHOW_EPISODES_ID, bewertung).subscribe(result => {
      });
  }

  // Benutzer markiert Episode als gesehen/ungesehen
  public changeEpisodWatched(gesehen: boolean) {
    this.episode.EPISODE_GESEHEN = gesehen;
  }

  public async loadFromLocalStorage(settingName) {
    return await this.storage.get(`setting:${settingName}`);
  }

}
