import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MovieSearchResult } from '../shared/movie.search.result.model';
import { TVShowlistService } from '../shared/tvshowlist.service';
import { Storage } from '@ionic/storage';
import { IUser } from '../shared/user.model';
import { ToastController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-tvshow-search-modal',
  templateUrl: './tvshow-search-modal.component.html',
  styleUrls: ['./tvshow-search-modal.component.scss'],
})
export class TvshowSearchModalComponent implements OnInit {
  @ViewChild('inputbox', { static: false }) inputBox;
  public userModel: IUser;
  public tvwatchlistid: string;
  public tvshowsearch: Array<MovieSearchResult>;
  public tvshowsToAdd: Array<MovieSearchResult>;
  public btnTVShowAdd: boolean;
  private isLoading: boolean;
  public searchTitel: string;

  constructor(
    private localStorage: Storage,
    private navParams: NavParams,
    public toastCtrl: ToastController,
    private changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public tvShowlistService: TVShowlistService
  ) {
    this.btnTVShowAdd = false;
    this.tvshowsearch = [];
    this.tvshowsToAdd = [];
    this.userModel = null;

    // Übergabe Parameter auslesen
    this.tvwatchlistid = this.navParams.get('itvwatchlistid');
    console.log('tvwatchlistid', this.tvwatchlistid);
    this.userModel = this.navParams.get('iuser');
  }

  ngOnInit() {
    // Focus setzen in der Suchleiste
    setTimeout(() => {
      this.inputBox.setFocus();
    }, 150);
  }

  searchTVShowInput(search: any) {
    this.searchTitel = search.target.value;

    // Wenn die Suchbox leer ist Ergebnisse löschen
    if (this.searchTitel.length === 0) {
      this.tvshowsearch = [];
    }

    // Trigger refresh
    this.changeDetectorRef.detectChanges();
  }

  // TvShow wurde angetippt -> TVShow checked
  tvShowChecked(ithemoviedbid: string, ititel: string, icover: string) {

    // Prüfen ob die Serie gecheckt wurde oder nicht
    const tempMovie: MovieSearchResult = {
      THEMOVIEDB_ID: ithemoviedbid,
      TITLE: ititel,
      COVER_URL: icover,
      CHECKED: true
    };

    // Füge Film zur Add List
    this.tvshowsToAdd.push(tempMovie);

    // Item entfernen
    this.tvshowsearch = this.tvshowsearch.filter(mo => mo.THEMOVIEDB_ID !== ithemoviedbid);

    // Prüfen ob mindestens ein Film in der liste ist
    if (this.tvshowsToAdd.length > 0) {
      // Button Hinzufügen anzeigen
      this.btnTVShowAdd = true;
    } else {
      // Button Hinzufügen ausblenden
      this.btnTVShowAdd = false;
    }
  }

  // Film aus Liste entfernen und wieder in den SearchResult schreiben
  badgeRemoveItem(ithemoviedbid: string) {
    // prüfen ob eine themoviedbid mit gegeben wurde
    if (ithemoviedbid !== 'undefined') {

      // Film zwischen speichern
      const delMovAdd: MovieSearchResult = this.tvshowsToAdd.find(delMo => delMo.THEMOVIEDB_ID === ithemoviedbid);
      delMovAdd.CHECKED = false;

      // schmeiße Film aus der Liste
      this.tvshowsToAdd = this.tvshowsToAdd.filter(obj => obj.THEMOVIEDB_ID !== ithemoviedbid);

      // Film in die SearchResult Liste pushen
      this.tvshowsearch.push(delMovAdd);

      // Wenn keine Filme mehr zum hinzufügen vorhanden sind
      if (this.tvshowsToAdd.length < 1) {
        this.btnTVShowAdd = false;
      }
    }
  }

  // TVShow zur Watchlist hinzufügen
  public addTVShowToWatchlist() {

    this.presentLoadingLayer();
    // Alle Serien hinzufügen
    this.tvshowsToAdd.forEach(tvshow => {
      console.log(tvshow);
      this.tvShowlistService.addMoviesToWatchlist(this.userModel, this.tvwatchlistid, tvshow.THEMOVIEDB_ID).subscribe(httpResult => {
        // Wenn Anfrage geklappt hat
        if (httpResult.RESPONSE_MESSAGE === 'OK') {
          this.dismiss();
        } else {
          this.presentToast('Errorcode: ' + httpResult.ERROR_CODE + ' | ' + httpResult.ERROR_MESSAGE);
        }
      });
    });

    // FIXME: Vernünftige Rückgabe vom Backend, vorerst einfach Modal schließen
    this.dismiss();

    this.dismissLoadingLayer().then(() => {
      this.dismiss();
    });
  }

  // Modal schließn
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  // Hole Daten aus dem Speicher
  public async loadFromLocalStorage(settingName) {
    return await this.localStorage.get(`setting:${settingName}`);
  }

  // Speichere Daten im Speicher
  public saveToLocalStorage(settingName, value) {
    return this.localStorage.set(`setting:${settingName}`, value);
  }

  async presentLoadingLayer() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then();
        }
      });
    });
  }

  async dismissLoadingLayer() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then();
  }

  // Suchbox leeren
  public clearInput(ievent: any) {
    if (ievent.target.value === '') {
      // Suche leeren
      this.tvshowsearch = null;
    }
  }

  // Suchbutton NEU:
  public startSearch() {
    if (this.searchTitel.length > 1) {
      // Suche nach dem Film über API
      // TODO: TVShow Suche starten
      this.tvShowlistService.searchNewTVShow(this.searchTitel).subscribe(searchResult => {
        this.tvshowsearch = searchResult;
      });
      // this.movieSearchService.searchNewMovie(this.searchTitel).subscribe(searchResult => {
      //   this.moviesearch = searchResult;
      // });
    } else {
      this.presentToast('Bitte einen Titel eingeben');
    }
  }
}
