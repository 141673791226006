import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from './httpresponse.model';
import { MovieComment } from './movie.comment.model';

@Injectable()

export class MovieDetailService {
  private cover: string;
  private currentMoMovieId: number;
  private oldMovieComment: string;
  public apiUrl: string;

  constructor(private http: HttpClient) {
    // API-URL
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  // Benutzer-Bewertung eines Films speichern
  public setMovieRanking(ibabenutzerid: number, iapikey: string, imomovieid: number, ibewertung: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'setMovieUserRanking?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_movie_id=' + imomovieid + '&bewertung=' + ibewertung);
  }

  // Film Kommentare holen
  public getMovieComments(ibabenutzerid: number, iapikey: string, imomovieid: number): Observable<Array<MovieComment>> {
    return this.http.get<Array<MovieComment>>(this.apiUrl + 'getMovieComments?ba_benutzer_id=' +
      ibabenutzerid + '&api_key=' + iapikey + '&mo_movie_id=' + imomovieid);
  }

  // Film Kommentar speichern
  public setMovieComment(ibabenutzerid: number, iapikey: string, imomovieid: number, icomment: string): Observable<HttpResponse> {
    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('mo_movie_id', imomovieid.toString());
    body.append('comment', icomment);


    const result = this.http.post<HttpResponse>(this.apiUrl + 'setMovieComment', body, options);
    return result;
  }

  public setCover(icover: string) {
    this.cover = icover;
  }

  public getCover() {
    return this.cover;
  }

  public setCurrentMoMovieId(imovieid: number) {
    this.currentMoMovieId = imovieid;
  }

  public getCurrentMoMovieId() {
    return this.currentMoMovieId;
  }

  public setOldComment(icomment: string) {
    this.oldMovieComment = icomment;
  }

  public getOldComment() {
    return this.oldMovieComment;
  }
}
