import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Friend } from './friend.model';
import { Chat } from './chat.model';
import { HttpResponse } from './httpresponse.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FriendsService {
  public apiUrl: string;

  constructor(private http: HttpClient) {
    // http://127.0.0.1/api-test/login.php
    this.apiUrl = 'http://81.169.138.242/php-rest-api/api.php/watchlist/';
  }

  getFriendList(ibabenutzerid: number, iapikey): Observable<Array<Friend>> {
    return this.http.get<Array<Friend>>(this.apiUrl + 'getFriendList' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey);
  }

  getChatVerlauf(ibabenutzerid: number, iapikey, ibafriendid: string): Observable<Array<Chat>> {
    return this.http.get<Array<Chat>>(this.apiUrl + 'getFriendChat' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key='
      + iapikey + '&ba_friend_id=' + ibafriendid);
  }

  // Sende eine Freundschaftsanfrage
  sendFriendRequest(ibabenutzerid: number, iapikey: string, ifriendCode: string): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'baSendFriendRequest' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key='
      + iapikey + '&friend_code=' + ifriendCode);
  }

  // Freundschaftsanfrage Action -> Bestätigen oder Abbrechen
  friendRequestAction(ibabenutzerid: number, iapikey: string, ibafriendid, iaction: number): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(this.apiUrl + 'friendRequestAction' + '?ba_benutzer_id=' + ibabenutzerid + '&api_key='
      + iapikey + '&ba_friend_id=' + ibafriendid + '&action=' + iaction);
  }

  // Sende Nachricht
  sendMessageToUser(ibabenutzerid: number, iapikey: string, ibafriendid: string, inachricht: string) {
    const httpHeaders = new HttpHeaders();

    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append('Accept', 'application/json');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST');
    httpHeaders.append('Access-Control-Allow-Origin', '*');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    body.append('ba_benutzer_id', ibabenutzerid.toString());
    body.append('api_key', iapikey);
    body.append('ba_friend_id', ibafriendid);
    body.append('nachricht', inachricht);


    const result = this.http.post<HttpResponse>(this.apiUrl + 'sendMessage', body, options);
    return result;
  }

  // Hole Freundesliste
  async getFriends(ibabenutzerid: number, iapikey): Promise<Array<Friend>> {
    return await this.http.get<Array<Friend>>(this.apiUrl + '?ba_benutzer_id=' + ibabenutzerid + '&api_key=' + iapikey).toPromise();
  }
}
