import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { IUser } from './shared/user.model';
import { Menu } from './shared/menu.model';
import { UserSerivce } from './shared/user.service';
import { AuthService } from './shared/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public userMenu: Array<Menu>;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Film Watchlists',
      url: '/movie-watchlist',
      icon: 'videocam'
    },
    {
      title: 'Serien Watchlists',
      url: '/tvshow-watchlist',
      icon: 'tv'
    },
    {
      title: 'Freunde',
      url: '/friends',
      icon: 'people'
    }
  ];

  public profilimg: string;
  public userModel: IUser;
  public menuVisibility: boolean;
  public abmelden: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public storage: Storage,
    public navCtrl: NavController,
    public userService: UserSerivce,
    public authService: AuthService
  ) {
    this.abmelden = false;
    this.initializeApp();
    this.menuVisibility = false;
    this.profilimg = './assets/no_profil.png';
    this.userService.getUserObj().subscribe(result => {
      this.userModel = result;
    });

    this.authService.getUserModuleMenu().subscribe(menu => {
      this.userMenu = menu;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // User eingeloggt?
      this.loadFromLocalStorage('USER').then(result => {

        // Wenn es einen User im Speicher gibt
        if (result != null) {
          if (!result.LOGGEDIN) {
            // Benutzer ist nicht angemeldet
            this.navCtrl.navigateRoot('login');
          } else {
            // Benutzer ist angemeldet
            this.userModel = result;
            this.menuVisibility = true;
            this.abmelden = true;
            // Menü aus dem lokalen speicher holen
            this.loadFromLocalStorage('USERMENU').then(userModule => {
              this.authService.setUserModuleMenu(userModule);
            });
            this.navCtrl.navigateRoot('home');

          }
        } else {
          this.navCtrl.navigateRoot('login');
        }
      });
    });
  }

  // Benutzer abmelden
  logout() {
    // UserModel leeren
    this.userService.sendUserObj(null);
    this.clearLocalStorage('USER');
    // Menü leeren
    this.authService.setUserModuleMenu(null);
    this.clearLocalStorage('USERMENU');
    this.abmelden = false;
    // Zur Login-Page navigieren
    this.navCtrl.navigateRoot('login');
  }

  openProfil() {

    // profilModul ID aus der Menuliste ziehen
    let profilModul = 0;
    profilModul = this.userMenu.find(obj => obj.MODUL_NAME === 'Profil').BA_BENUTZER_MODULE_ID;
    // Wenn ProfilModul freigeschaltet ist dann anzeigen
    if (profilModul > 0) {
      this.navCtrl.navigateRoot('profil');
    }
  }

  public async loadFromLocalStorage(settingName) {
    return await this.storage.get(`setting:${settingName}`);
  }

  public async clearLocalStorage(settingName) {
    return await this.storage.set(`setting:${settingName}`, null);
  }

  // TEST
  logging(ilog) {
    this.navCtrl.navigateForward(ilog);
  }
}
