import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { IUser } from '../shared/user.model';
import { MovieDetailService } from '../shared/movie.detail.service';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit {
  private userModel: IUser;
  private momovieid: number;
  public commentText: string;
  public btnSaveNewComment: boolean;
  public oldComment: boolean;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private localStorage: Storage,
    private movieDetailService: MovieDetailService
  ) {
    this.btnSaveNewComment = false;
    this.oldComment = false;
  }

  ngOnInit() {
    // Lade User aus dem Speicher
    this.loadFromLocalStorage('USER').then(benutzer => {
      this.userModel = benutzer;
      this.momovieid = this.movieDetailService.getCurrentMoMovieId();
      this.commentText = this.movieDetailService.getOldComment();
      if (this.commentText) {
        this.oldComment = true;
      }
    });
  }

  // Neuen Kommentar speichern
  public saveNewComment() {
    // Prüfen ob ein Kommentar eingegeben wurde
    if (this.commentText.length > 0) {
      if (this.momovieid) {
        // Neuen Kommentar speichern
        this.movieDetailService.setMovieComment(this.userModel.BA_BENUTZER_ID, this.userModel.API_KEY,
          this.momovieid, this.commentText).subscribe(res => {
            if (res.RESPONSE_MESSAGE === 'OK') {

              // Wenn der Kommentar gespeichert wurde Modal schließen
              this.dismiss();
            }
          });
      }
    }
  }

  public commentTextChange(ievent: any) {
    // prüfen ob eine Eingabe gemacht wurde
    if (ievent.target.value.length > 0) {
      // Wenn ja den Button freischalten
      this.btnSaveNewComment = true;
      this.commentText = ievent.target.value;
    } else {
      // Wenn keine Zeichen eingegeben wurden oder Eingabe gelöscht wurde
      this.commentText = '';
      this.btnSaveNewComment = false;
    }
  }

  // Modal schließen
  public dismiss() {
    this.modalController.dismiss();
  }

  // Hole Daten aus dem lokalen Speicher
  public async loadFromLocalStorage(settingName) {
    return await this.localStorage.get(`setting:${settingName}`);
  }

}
